/* nopayment.css */

.container {
  margin-top: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 120vh;
  width: 100%;
  padding-left: 10vw;
  max-width: 1920px;
  max-height: 850px;
}

.form-container {
    display: flex;
    justify-content: space-evenly;
  width: 600px;
  height: 50vh;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h1{
    text-align: center;
    font-size: x-large;
    padding-bottom: 10px;
}

/* Style for the input fields */
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style for the submit button */
/* Modify the button[type="submit"] style */
/* Style for the submit button */
button[type="submit"] {
  background: linear-gradient(to bottom, maroon, red);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Style for the submit button on hover */
button[type="submit"]:hover {
  background: linear-gradient(to bottom, gray, white);
  color: maroon;
}
