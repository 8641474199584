.App {
  text-align: center;
}

/* *{
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}  */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Language: css */
/* slidshow testing ======================= */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  justify-content: center;
  margin-top: 1rem;
  width: 90vw;
  align-items: center;
}

.slide {
  display: inline-block;
  margin: 0px 0rem;

  height: 150px;
}
.slide_small {
  display: inline-block;
  margin: 0px 0rem;

  height: 150px;
}
/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

@media (max-width: 600px) {
  .slide,
  .slide_small {
    width: 100%;
  }
}

/* map component css */
